<template>
  <div>
    <div v-if="path == '/clientes/finish' || path == '/colaborador/finish'" :class="banner">
      <br>

    </div>

    <div class=" text-center footer">
      <br>

      <div class="text-center image">

        <!-- <v-img contain max-height="160px" :src="LogoFairfax" max-width="245" style=" margin: auto; " /> -->

      </div>

      <div :class="copyrightBox" class="text-center">
        <label style="color: white;font-weight: 700;">Copyright© 2025 Southbridge Compañía de Seguros Generales S.A. Todos los Derechos Reservados.</label>
      </div>


    </div>

  </div>
</template>

<script>
import LogoFairfax from "@/assets/images/FairfaxLogo.svg";

export default {
  name: "Footer",
  mounted() {
  },
  data: () => ({
    LogoFairfax,
    copyrightBox: null,
    path: null,
    puntos: null,
    screen: null,
    banner: null,
    texto: null,
  }),
  mounted() {
    this.path = window.location.pathname;
    if (this.path == '/clientes/finish') {
      this.puntos = '7.000'
    } else if (this.path == '/colaborador/finish') {
      this.puntos = '10.000'
    }
    this.screen = window.screen.width
    if (this.screen > 767) {
      this.banner = 'text-center banner'
      this.texto = 'texto'
      this.copyrightBox = 'copyrightBox'
    } else {
      this.banner = 'text-center banner2'
      this.texto = 'texto2'
      this.copyrightBox = 'copyrightBox2'
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.copyrightBox{
  background-color:rgba(0, 7, 47, 1);
  height: 50px; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  margin-top: 2.1%;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}
.copyrightBox2{
  background-color:rgba(0, 7, 47, 1);
  height: 50px; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  margin-top: 7.5%;
  font-size: 12px;
  font-family: 'Arial', sans-serif;
}

.cobertura {
  text-align: center;

  letter-spacing: 0.3px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
}

.detalle {
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  opacity: 1;
  font-family: 'Arial', sans-serif;

}

// .footer {
//    height: 130px;
//    background: #2D387C 0% 0% no-repeat padding-box;
// }

// .banner {
  //height: 105%; 
  // background: #FFF;
// }

.banner2 {
  height: 50%;
  // background: #0069B6;
}

.texto {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  text-transform: initial;
}

.texto2 {
  width: 750px;
  height: 29px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  text-transform: initial;
}

.butoon {
  width: 310px;
  height: 64px;
  background: #EF7C00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 30px Helvetica Neue;
  text-transform: initial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
}

.butoon2 {
  background: #EF7C00 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 30px Helvetica Neue;
  text-transform: initial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.theme--light.v-btn.v-btn--has-bg {
  background: #EF7C00 0% 0% no-repeat padding-box;
}
.image{
  margin-top: 0%;
  margin-bottom: 1.5%;
}
</style>
