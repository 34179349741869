<template>
  <v-app>

    <div v-if="screen >= 767">
      <v-img contain :src="appImagenBar" />
    </div>

    <div v-if="screen < 767">
      <v-img contain :src="appImagenMovileBar" max-width="95%" style="margin-top: 5%;" />
    </div>

    <div v-if="screen >= 767 && !this.pathname.includes('finish') && !this.pathname.includes('error')">
      <v-btn :class="btnBack" @click="redirectBack">
        <v-icon class="mr-2" size="15">mdi-less-than</v-icon>
        Volver atrás
      </v-btn>
    </div>

    <div v-if="screen < 767 && !this.pathname.includes('finish') && !this.pathname.includes('error')">
      <v-btn :class="btnBack" @click="redirectBack">
        <v-icon color="white" class="mr-2" size="15">mdi-less-than</v-icon>
      </v-btn>
    </div>

    <v-main>
      <router-view />
    </v-main>

    <Tarjetas />
    <Footer />

  </v-app>
</template>

<script>
import appImagenBar from "@/assets/images/appBar.png";
import appImagenMovileBar from "@/assets/images/appBarMovile.png";

import Footer from "@/components/Footer.vue";
import Tarjetas from "@/components/Tarjetas.vue";

import CryptoJS from 'crypto-js';

export default {
  components: {
    Footer,
    Tarjetas,
  },
  data: () => ({
    appImagenBar,
    appImagenMovileBar,

    screen: window.screen.width,
    btnBack: '',
    pathname: window.location.pathname,
    secretKey: process.env.VUE_APP_CRYPT,
    arrayShoppingCart: [],
    servicesData: null,

  }),
  mounted() {

    if (this.screen > 767) {
      if (this.pathname.includes('inicio')) {
        this.btnBack = 'btnBack';
      } else {
        this.btnBack = 'btnBackMore';
      }
    } else {
      this.btnBack = 'btnBackMovile';
    }
  },
  methods: {
    redirectBack() {

      const arrayShoppingCartAux = localStorage.getItem('arrayShoppingCart');
      const servicesDataAux = localStorage.getItem('servicesData');

      if (arrayShoppingCartAux) {
        const bytesArrayShoppingCart = CryptoJS.AES.decrypt(arrayShoppingCartAux, this.secretKey);
        const decryptedArrayShoppingCart = bytesArrayShoppingCart.toString(CryptoJS.enc.Utf8);
        this.arrayShoppingCart = JSON.parse(decryptedArrayShoppingCart);
      }

      if (servicesDataAux) {
        const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
        const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
        this.servicesData = JSON.parse(decryptedServicesData);
      }

      if (this.pathname.includes('inicio')) {
        window.location.href = process.env.VUE_APP_REDIRECT;
      } else if (this.pathname.includes('datos')) {
        window.location.pathname = this.pathname.replace(/datos/, 'inicio');
      } else if (this.pathname.includes('resumen')) {

        this.servicesData.e1 = 1;

        this.arrayShoppingCart.pop();

        const arrayShoppingCartLocal = JSON.stringify(this.arrayShoppingCart);
        const encryptedArrayShoppingCartLocal = CryptoJS.AES.encrypt(arrayShoppingCartLocal, this.secretKey).toString();
        localStorage.setItem('arrayShoppingCart', encryptedArrayShoppingCartLocal);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        localStorage.setItem('servicesData', encryptedServicesDataLocal);

        window.location.pathname = this.pathname.replace(/resumen/, 'datos');
      } else if (this.pathname.includes('consulta')) {
        window.location.pathname = this.pathname.replace(/consulta/, `${this.servicesData.typeCliente}/inicio`);
      } else {
        console.warn('Ruta no reconocida:', this.pathname);
      }
    },

  }
};
</script>

<style>
.btnBack {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: rgba(51, 51, 51, 1) !important;
  font-weight: bold !important;
  letter-spacing: 0.4px;
  font-size: 20px !important;
  margin-top: 0%;
  margin-left: 2%;
}

.btnBackMore {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: rgba(51, 51, 51, 1) !important;
  font-weight: bold !important;
  letter-spacing: 0.4px;
  font-size: 20px !important;
  margin-top: -3%;
  margin-left: 2%;
  min-height: 150px;
}

.btnBackMovile {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: rgba(51, 51, 51, 1) !important;
  font-weight: bold !important;
  letter-spacing: 0.4px;
  font-size: 14px !important;
  margin-top: -20%;
  margin-left: 2%;
}
</style>