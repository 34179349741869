<template>
  <div :class="backgroundView">

    <div>
      <div v-if="screen > 767" style="border-radius: 6px;">
        <v-img contain :src="BannerDesktop"
          style="max-width: 95%; margin: auto; margin-top: 2%; border-radius: 18px !important;" />
      </div>
    </div>

    <div :class="bannerView">
      <div v-if="screen <= 767" style="border-radius: 6px;">
        <v-img contain :src="BannerMobile" style="border-radius: 6px !important;" />
      </div>
    </div>

    <div :class="cartView" v-if="screen > 767">
      <CarritoCompra id="home_1" :patentes="arrayShoppingCart"></CarritoCompra>
    </div>

    <div v-if="servicesData.loadSoap" class="text-center">

      <br><br><br><br><br>

      <v-progress-circular :size="100" color="rgba(0,105,185, 1)" indeterminate></v-progress-circular>

      <br><br>

      Cargando

    </div>

    <div v-if="servicesData.loadSoap == false" :class="topView">

      <v-form v-if="servicesData.newCar != true" class="text-center" ref="vehicleForm" v-model="valid">

        <div :class="dataBox">

          <div class="title" :class="formBox">

            <div :class="titleBox">

              <b :class="title1View">Vigente desde el</b>
              <b :class="title2View">1 de abril del 2025</b>

            </div>

            <v-container>

              <div :class="patentFieldContainer">
                <label :class="patentLabelView">Ingresa tu patente</label>
                <v-text-field :class="patentFieldView" v-model="carData.patente" :rules="patenteRules" maxlength="6"
                  minlength="5" hide-details="auto" required solo @change="Mayus()"></v-text-field>
              </div>

              <br v-if="screen <= 767">

              <div :class="mailFieldContainer">
                <label :class="mailLabelView">Ingresa tu correo</label>
                <v-text-field :class="mailFieldView" v-model="propietarioData.email" :rules="emailRules"
                  @change="emailValidate()" required solo></v-text-field>
              </div>

              <br v-if="screen <= 767">

              <div :class="captchaView">
                <vue-recaptcha id="captcha_1" ref="recaptcha" @verify="verifyMethod"
                  sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />
              </div>

              <v-btn :class="btnSearchView" id="avanza_1" @click="validate()">CONTINUAR</v-btn>

              <br> <br v-if="screen <= 767">

              <div></div>

              <br v-if="screen > 767">
              <br v-if="screen > 767">

            </v-container>

          </div>

          <br v-if="screen <= 767">
          <br v-if="screen <= 767">

          <div :class="pricesView">

            <div :class="priceTitleBox">
              <b :class="title1View">Precios </b>
              <b :class="title2View">2025</b>
            </div>

            <div :class="priceTag">

              <v-img contain :src="Auto" max-width="40" class="images" />

              <label :class="tituloLargo">
                {{ planData.soap.car.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                <div style="display: flex; gap: 10px">
                  {{ planData.soap.car.pricePuntos }}
                  <v-img contain :src="puntosPrice" max-width="35" class="" />
                </div>
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.car.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Camioneta" max-width="40" class="images" />

              <label :class="tituloLargo">
                {{ planData.soap.camioneta.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                <div style="display: flex; gap: 10px">
                  {{ planData.soap.camioneta.pricePuntos }}
                  <v-img contain :src="puntosPrice" max-width="35" class="" />
                </div>
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.camioneta.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Moto" max-width="35" class="imagesMoto" />

              <label :class="tituloCorto">
                {{ planData.soap.moto.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                <div style="display: flex; gap: 10px">
                  {{ planData.soap.moto.pricePuntos }}
                  <v-img contain :src="puntosPrice" max-width="35" class="" />
                </div>
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.moto.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Carro" max-width="40" class="images" />

              <label :class="tituloLargo">
                {{ planData.soap.arrastre.name }}
              </label>

              <label :class="priceSpaceCarro"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                <div style="display: flex; gap: 10px">
                  {{ planData.soap.arrastre.pricePuntos }}
                  <v-img contain :src="puntosPrice" max-width="35" class="" />
                </div>
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.arrastre.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Minibus" max-width="35" class="imagesMiniBus" />

              <label :class="tituloMediano">
                {{ planData.soap.bus.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                <div style="display: flex; gap: 10px">
                  {{ planData.soap.bus.pricePuntos }}
                  <v-img contain :src="puntosPrice" max-width="35" class="" />
                </div>
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.bus.price) }}
              </label>

            </div>

          </div>
        </div>

      </v-form>

    </div>

    <div v-if="servicesData.loadSoap == false" :class="topViewNew">

      <v-form v-if="servicesData.newCar == true" class="text-center" ref="newVehicleForm" v-model="valid2">

        <br><br><br v-if="screen > 767">

        <div :class="dataBoxAdd">

          <div :class="cartView" v-if="screen <= 767">
            <CarritoCompra id="home_1" :patentes="arrayShoppingCart"></CarritoCompra>
          </div>

          <div class="title" :class="formBox">
            <div :class="titleBoxAdd">
              <b :class="title1View">Agrega otro</b>
              <b :class="title2View">auto</b>
            </div>

            <br v-if="screen <= 767">

            <v-container>
              <div :class="patentFieldContainer">
                <label :class="patentLabelView">Ingresa tu patente</label>
                <v-text-field :class="patentFieldView" v-model="carData.patente" :rules="patenteRules" maxlength="6"
                  minlength="5" label="Ej: ABDC12" required solo @change="Mayus()"></v-text-field>
              </div>

              <br v-if="screen <= 767">

              <div :class="captchaView">
                <vue-recaptcha ref="recaptcha" @verify="verifyMethod"
                  sitekey="6LdZzfwiAAAAANWXDzgv_Qi4HVI3aV7SwHpwd8-u" />
              </div>

              <div :class="buttonsNewCarView" style="margin:auto;">
                <v-btn :class="btnSearchNewCarView" @click="validate()">Continuar</v-btn>
                <br v-if="screen <= 767">
                <br v-if="screen <= 767">
                <v-btn :class="btnBackToPayView" @click="btnBackToPay()">Volver</v-btn>
              </div>

            </v-container>

          </div>

          <br v-if="screen <= 767">
          <br v-if="screen <= 767">

          <div :class="pricesView">

            <div style="text-align: left; margin-bottom: 2%;margin-top: -2%;">
              <b :class="title1View">Precios </b>
              <b :class="title2View">2025</b>
            </div>

            <div :class="priceTag">

              <v-img contain :src="Auto" max-width="40" class="images" />

              <label :class="tituloLargo">
                {{ planData.soap.car.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                {{ formatPrice(planData.soap.car.pricePuntos) }}
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.car.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Camioneta" max-width="40" class="images" />

              <label :class="tituloLargo">
                {{ planData.soap.camioneta.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                {{ formatPrice(planData.soap.camioneta.pricePuntos) }}
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.camioneta.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Moto" max-width="35" class="imagesMoto" />

              <label :class="tituloCorto">
                {{ planData.soap.moto.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                {{ formatPrice(planData.soap.moto.pricePuntos) }}
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.moto.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Carro" max-width="40" class="images" />

              <label :class="tituloLargo">
                {{ planData.soap.arrastre.name }}
              </label>

              <label :class="priceSpaceCarro"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                {{ formatPrice(planData.soap.arrastre.pricePuntos) }}
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.arrastre.price) }}
              </label>

            </div>

            <br>

            <div :class="priceTag">

              <v-img contain :src="Minibus" max-width="35" class="imagesMiniBus" />

              <label :class="tituloMediano">
                {{ planData.soap.bus.name }}
              </label>

              <label :class="priceSpace"> | </label>

              <label :class="subtitulo" v-if="$route.path.includes('/puntos')">
                {{ formatPrice(planData.soap.bus.pricePuntos) }}
              </label>
              <label :class="subtitulo" v-else>
                {{ formatPrice(planData.soap.bus.price) }}
              </label>

            </div>

          </div>
        </div>

      </v-form>

    </div>

    <br v-if="screen > 767">
    <br v-if="screen <= 767">

    <div v-if="servicesData.loadSoap == false" class="sub">
      <br>
      <v-col :class="btnFindSoapBox" class="text-center">
        <div :class="btnFindSoapTitleBox">
          <b :class="title1View">¿Ya tienes tu SOAP?</b>
          <b :class="title2View">Descarga tu certificado aquí</b>
        </div>
        <v-btn :class="btnFindSoapView" id="consulta_1" @click="redirectFindSoap()">CONSULTA TU SOAP</v-btn>

      </v-col>

    </div>

    <div :class="disclaimerText">
      <br>
      <label>La compañía que cubre el riesgo es Southbridge Compañía de Seguros Generales S.A. y la póliza es
        intermediada por Cencosud Corredores de Seguros y Servicios S.A. El detalle de las condiciones y exclusiones
        están contenidas en la póliza incorporada al depósito de pólizas de la Comisión para el Mercado Financiero
        (www.cmfchile.cl) bajo el código POL 3 2013 0487.
      </label>
    </div>

  </div>

</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

import Auto from "@/assets/images/NewCarIcon.svg";
import Camioneta from "@/assets/images/NewVanIcon.svg";
import Carro from "@/assets/images/NewDragCartIcon.svg";
import Minibus from "@/assets/images/NewMiniBusIcon.svg";
import Moto from "@/assets/images/NewMotoIcon.svg";
import puntosPrice from "@/assets/images/puntosprice.png";

import BannerClientesMobile from "@/assets/images/BannerClientesMobile.png";
import BannerClientesDesktop from "@/assets/images/BannerClientesDesktop.png";

import BannerNoClientesMobile from "@/assets/images/BannerNoClientesMobile.png";
import BannerNoClientesDesktop from "@/assets/images/BannerNoClientesDesktop.png";

import BannerColaboradoresMobile from "@/assets/images/BannerColaboradoresyPymeMobile.jpg";
import BannerColaboradoresDesktop from "@/assets/images/BannerColaboradoresyPymeDesktop.jpg";

import BannerPymeMobile from "@/assets/images/BannerPuntosMobile.png";
import BannerPymeDesktop from "@/assets/images/BannerPuntosDesktop.png";

import ShieldService from "../services/shield.service";
import CarritoCompra from "@/components/Carrito.vue";

import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

export default {
  name: "InitForm",
  components: {
    VueRecaptcha,
    CarritoCompra,
  },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,

    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),

    backgroundView: null,
    bannerView: null,
    topView: null,
    pricesView: null,
    priceTag: null,
    tituloMediano: null,
    tituloCorto: null,
    tituloLargo: null,
    subtitulo: null,
    title1View: null,
    title2View: null,
    cartView: null,
    colView: null,
    dataBox: null,
    dataBoxAdd: null,
    formBox: null,
    priceSpace: null,
    priceSpaceCarro: null,
    mailFieldContainer: null,
    patentFieldContainer: null,
    patentFieldView: null,
    mailFieldView: null,
    priceTitleBox: null,
    patentLabelView: null,
    captchaView: null,
    btnSearchView: null,
    btnFindSoapView: null,
    btnFindSoapTitleBox: null,
    carBox: null,
    titleView: null,
    btnBackToPayView: null,
    btnBack: '',
    disclaimerText: null,
    title2NewCarView: null,
    titleNewCarView: null,
    patentFieldNewCarView: null,
    mailFieldNewCarView: null,
    patentLabelNewCarView: null,
    mailLabelNewCarView: null,
    captchaNewCarView: null,
    title1NewCarView: null,
    btnSearchNewCarView: null,
    btnFindSoapBox: null,
    titleBox: null,
    titleBoxAdd: null,
    topViewNew: null,
    puntosPrice,
    Auto,
    Camioneta,
    Carro,
    Minibus,
    Moto,
    BannerMobile: null,
    BannerDesktop: null,
    BannerClientesMobile,
    BannerClientesDesktop,
    BannerNoClientesMobile,
    BannerNoClientesDesktop,
    BannerColaboradoresMobile,
    BannerColaboradoresDesktop,
    BannerPymeMobile,
    BannerPymeDesktop,

    captcha: null,

    screen: window.screen.width,

    arrayShoppingCart: [],

    servicesData: {
      loadSoap: false,
      loadPlans: false,
      newCar: false,
      keygen: null,
      token: null,
      priceTotal: 0,
      priceDonation: 0,
      priceFinal: 0,
      entityDonation: null,
      optionDonate: true,
      secretDonation: null,
      rutPropietario: null,
      e1: 1,
      typePlan: null,
      typeCliente: null,
      loadInitial: false,
    },

    carData: {
      id: null,
      patente: null,
      patenteDv: null,
      year: null,
      type: null,
      marca: null,
      model: null,
      motor: null,
      price: null,
      externalProductId: null,
      newFlujo: false,
    },

    propietarioData: {
      rut: null,
      nombres: null,
      firtlastname: null,
      secondlastname: null,
      email: null,
      phoneNumber: null,
      propietario: true,
      rutPropietario: null,
      remarque: null,
      pricePuntos: null,
      campana: null,
    },

    planData: {},

    donationData: [],

    vehicleForm: false,
    newVehicleForm: false,

    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || "Ingrese un correo válido",
    ],
    patenteRules: [
      (v) => !!v || "El número de patente es requerido",
      (v) => (v && v.length <= 6) || 'La patente debe tener máximo 6 caracteres',
      (v) => (v && v.length > 4) || 'La patente debe tener mínimo 5 caracteres',
      (v) => /^[^\s]+$/.test(v) || 'La patente no puede contener espacios',
      (v) => /^[A-Za-z0-9]+$/.test(v) || 'La patente solo puede contener letras y números',
    ],
  }),
  methods: {
    redirectBack() {
      // console.log('typeCliente: ',this.servicesData.typeCliente) 
      switch (this.pathname) {
        case `/${this.servicesData.typeCliente}/datos`:
          window.location.href = `/${this.servicesData.typeCliente}/inicio`;
          // this.$router.push({ path: `/${this.servicesData.typeCliente}/inicio` });
          break;

        case `/${this.servicesData.typeCliente}/resumen`:
          window.location.href = `/${this.servicesData.typeCliente}/datos`;
          localStorage.setItem('backButton', 'SummaryToData');
          // this.$router.push({ path: `/${this.servicesData.typeCliente}/datos` });
          break;

        default:
          break;
      }
    },
    btnBackToPay() {

      this.servicesData.newCar = false;
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      localStorage.setItem('servicesData', encryptedServicesDataLocal);

      window.location.href = `/${this.servicesData.typeCliente}/resumen`
      // this.$router.push({ path: `/${this.servicesData.typeCliente}/resumen` });

    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async redirectFindSoap() {
      const consulta_1 = document.getElementById('consulta_1');
      window.location.href = `/consulta`
      // this.$router.push({ path: "/consulta" });
    },
    async validate() {
      const avanza_1 = document.getElementById('avanza_1');

      const vehicleForm = this.$refs.vehicleForm;
      const newVehicleForm = this.$refs.newVehicleForm;

      if (((vehicleForm && vehicleForm.validate()) ||
        //  (newVehicleForm && newVehicleForm.validate()))) {
        (newVehicleForm && newVehicleForm.validate())) && this.captcha == true) {

        this.servicesData.loadSoap = true;

        let largoPatente = this.carData.patente.length;

        if (largoPatente != 6) {
          let newPatente = this.carData.patente.match(/[a-z]+|[^a-z]+/gi)
          this.carData.patente = newPatente[0] + '0' + newPatente[1]
        }

        let verifyPatente = this.arrayShoppingCart.find(item => item.carData.patente === this.carData.patente);

        if (verifyPatente) {
          swal({
            title: "¡Patente duplicada!",
            text: "Ya ha ingresado esta patente al carrito de compra",
            icon: "info",
            button: "Ok",
          });
          this.servicesData.loadSoap = false;
        } else {

          let patenteAprobada = await ShieldService.consultasoapAprobado(this.carData.patente);

          if (patenteAprobada.status == 'ok') {
            swal({
              title: "¡Patente registrada!",
              text: "Esta patente ya cuenta con un seguro SOAP",
              icon: "info",
              button: "Ok",
            });
          } else {

            const carDataLocal = JSON.stringify(this.carData);
            const encryptedCarDataLocal = CryptoJS.AES.encrypt(carDataLocal, this.secretKey).toString();
            localStorage.setItem('carData', encryptedCarDataLocal);

            const propietarioDataLocal = JSON.stringify(this.propietarioData);
            const encryptedPropietarioDataLocal = CryptoJS.AES.encrypt(propietarioDataLocal, this.secretKey).toString();
            localStorage.setItem('propietarioData', encryptedPropietarioDataLocal);

            window.location.href = `/${this.servicesData.typeCliente}/datos`
            // this.$router.push({ path: `/${this.servicesData.typeCliente}/datos` });
          }

          this.servicesData.loadSoap = false;

          this.servicesData.e1 = 1;

          const servicesDataLocal = JSON.stringify(this.servicesData);
          const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
          localStorage.setItem('servicesData', encryptedServicesDataLocal);
        }

      }
    },
    onEvent() {
      // when you need a reCAPTCHA challenge
      this.$refs.recaptcha.execute();
    },
    verifyMethod() {
      const captcha_1 = document.getElementById('captcha_1');
      this.captcha = true;
    },
    Mayus() {
      this.carData.patente = this.carData.patente.replace(/\s+/g, '');
      this.carData.patente = this.carData.patente.toUpperCase();
    },
    emailValidate() {
      this.propietarioData.email = this.propietarioData.email.replace(/\s+/g, '');
    },
    returnPago() {
      window.location.href = process.env.VUE_APP_DOMINIO + `${this.servicesData.typeCliente}/resumen`;
    },
    async getDonations() {
      this.servicesData.loadDonations = true;
      let shieldRes = await ShieldService.getDonation();
      if (shieldRes.status == 'ok') {
        let auxDonation = [];
        auxDonation.push([shieldRes.data[0].donations[0], shieldRes.data[0].donations[1], shieldRes.data[0].donations[2]]);
        this.donationData.push(auxDonation);
        this.donationData.push(shieldRes.data[0].donations);
        const donationDataLocal = JSON.stringify(this.donationData);
        const encryptedDonationDataLocal = CryptoJS.AES.encrypt(donationDataLocal, this.secretKey).toString();
        localStorage.setItem('donationData', encryptedDonationDataLocal);
      }

      this.servicesData.loadDonations = false;
    },
    async getPlans() {

      this.servicesData.loadPlans = true;

      let shieldRes = await ShieldService.getPlans(this.servicesData.typePlan);

      if (shieldRes.status == 'ok') {

        this.planData = shieldRes.data[0];

        const planDataLocal = JSON.stringify(this.planData);
        const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
        localStorage.setItem('planData', encryptedPlanDataLocal);

      }

      this.servicesData.loadPlans = false;

    }
  },
  async mounted() {

    let path;
    let cliente;

    if (this.$route.path.includes('/general')) {
      path = 'soap-general-cenco';
      cliente = 'general';
      this.BannerMobile = this.BannerNoClientesMobile;
      this.BannerDesktop = this.BannerNoClientesDesktop;

    } else if (this.$route.path.includes('/clientes')) {
      path = 'soap-clientes-cenco';
      cliente = 'clientes';
      this.BannerMobile = this.BannerClientesMobile;
      this.BannerDesktop = this.BannerClientesDesktop;

    } else if (this.$route.path.includes('/colaborador')) {
      path = 'soap-colaborador-cenco';
      cliente = 'colaborador';
      this.BannerMobile = this.BannerColaboradoresMobile;
      this.BannerDesktop = this.BannerColaboradoresDesktop;
    } else if (this.$route.path.includes('/puntos')) {
      path = 'soap-puntos-cenco';
      cliente = 'puntos';
      this.BannerMobile = this.BannerPymeMobile;
      this.BannerDesktop = this.BannerPymeDesktop;
    }

    const arrayShoppingCartAux = localStorage.getItem('arrayShoppingCart');
    const propietarioDataAux = localStorage.getItem('propietarioData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const carDataAux = localStorage.getItem('carData');
    const planDataAux = localStorage.getItem('planData');
    const donationDataAux = localStorage.getItem('donationData');

    if (arrayShoppingCartAux) {
      const bytesArrayShoppingCart = CryptoJS.AES.decrypt(arrayShoppingCartAux, this.secretKey);
      const decryptedArrayShoppingCart = bytesArrayShoppingCart.toString(CryptoJS.enc.Utf8);
      this.arrayShoppingCart = JSON.parse(decryptedArrayShoppingCart);
      // console.log("arrayShoppingCart: ", this.arrayShoppingCart);

    } else {
      const arrayShoppingCartLocal = JSON.stringify(this.arrayShoppingCart);
      const encryptedArrayShoppingCartLocal = CryptoJS.AES.encrypt(arrayShoppingCartLocal, this.secretKey).toString();
      // localStorage.setItem('arrayShoppingCart', encryptedArrayShoppingCartLocal);
    }

    if (propietarioDataAux) {
      const bytesPropietarioData = CryptoJS.AES.decrypt(propietarioDataAux, this.secretKey);
      const decryptedPropietarioData = bytesPropietarioData.toString(CryptoJS.enc.Utf8);
      this.propietarioData = JSON.parse(decryptedPropietarioData);
      // console.log("propietarioData: ", this.propietarioData);

    } else {
      const propietarioDataLocal = JSON.stringify(this.propietarioData);
      const encryptedPropietarioDataLocal = CryptoJS.AES.encrypt(propietarioDataLocal, this.secretKey).toString();
      localStorage.setItem('propietarioData', encryptedPropietarioDataLocal);
    }

    if (servicesDataAux) {
      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      if (this.servicesData.typePlan != path) {
        localStorage.removeItem('carData');
        localStorage.removeItem('planData');
        localStorage.removeItem('propietarioData');
        localStorage.removeItem('servicesData');
        localStorage.removeItem('arrayShoppingCart');
        window.location.reload();
      }

    } else {
      this.servicesData.loadInitial = true;
      this.servicesData.typePlan = path;
      this.servicesData.typeCliente = cliente;
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
    }

    if (carDataAux) {
      const bytesCarData = CryptoJS.AES.decrypt(carDataAux, this.secretKey);
      const decryptedCarData = bytesCarData.toString(CryptoJS.enc.Utf8);
      this.carData = JSON.parse(decryptedCarData);
      // console.log("carData: ", this.carData);

    } else {
      const carDataLocal = JSON.stringify(this.carData);
      const encryptedCarDataLocal = CryptoJS.AES.encrypt(carDataLocal, this.secretKey).toString();
      localStorage.setItem('carData', encryptedCarDataLocal);
    }

    if (planDataAux) {
      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedPlanData = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedPlanData);
      // console.log("planData: ", this.planData);

    } else {

      this.getPlans();

    }

    if (donationDataAux) {
      const bytesDonationData = CryptoJS.AES.decrypt(donationDataAux, this.secretKey);
      const decryptedDonationData = bytesDonationData.toString(CryptoJS.enc.Utf8);
      this.donationData = JSON.parse(decryptedDonationData);
      // console.log("donationData: ", this.donationData);

    } else {

      this.getDonations();

    }

    if (this.screen > 1001) {

      this.backgroundView = 'backgroundView'
      this.bannerView = 'bannerView'
      this.topView = 'topView'
      this.pricesView = 'pricesView'
      this.priceTag = 'priceTag'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.cartView = 'cartView'
      this.colView = 'colView'
      this.patentFieldContainer = 'patentFieldContainer'
      this.mailFieldContainer = 'mailFieldContainer'
      this.patentFieldView = 'patentFieldView'
      this.mailFieldView = 'mailFieldView'
      this.patentLabelView = 'patentLabelView'
      this.mailLabelView = 'mailLabelView'
      this.captchaView = 'captchaView'
      this.btnSearchView = 'btnSearchView'
      this.carBox = 'carBox'
      this.btnFindSoapView = 'btnFindSoapView'
      this.titleView = 'titleView'
      this.btnBackToPayView = 'btnBackToPayView'
      this.title2NewCarView = 'title2NewCarView'
      this.titleNewCarView = 'titleNewCarView'
      this.patentFieldNewCarView = 'patentFieldNewCarView'
      this.mailFieldNewCarView = 'mailFieldNewCarView'
      this.patentLabelNewCarView = 'patentLabelNewCarView'
      this.mailLabelNewCarView = 'mailLabelNewCarView'
      this.captchaNewCarView = 'captchaNewCarView'
      this.buttonsNewCarView = 'buttonsNewCarView'
      this.title1NewCarView = 'title1NewCarView'
      this.btnSearchNewCarView = 'btnSearchNewCarView'
      this.btnFindSoapBox = 'btnFindSoapBox'
      this.dataBox = 'dataBox'
      this.dataBoxAdd = 'dataBoxAdd'
      this.formBox = 'formBox'
      this.priceSpace = 'priceSpace'
      this.priceSpaceCarro = 'priceSpace'
      this.tituloMediano = 'tituloMediano'
      this.tituloCorto = 'tituloCorto'
      this.tituloLargo = 'tituloLargo'
      this.subtitulo = 'subtitulo'
      this.btnFindSoapTitleBox = 'btnFindSoapTitleBox'
      this.titleBox = 'titleBox'
      this.titleBoxAdd = 'titleBoxAdd'
      this.priceTitleBox = 'priceTitleBox'
      this.topViewNew = 'topViewNew'
      this.btnBack = 'btnBack'
      this.disclaimerText = 'disclaimerText3'
    } else if (this.screen <= 767) {

      this.backgroundView = 'backgroundView2'
      this.bannerView = 'bannerView2'
      this.topView = 'topView2'
      this.pricesView = 'pricesView2'
      this.priceTag = 'priceTag2'
      this.title1View = 'title1View2'
      this.title2View = 'title2View2'
      this.cartView = 'cartView2'
      this.colView = 'colView2'
      this.patentFieldView = 'patentFieldView2'
      this.mailFieldView = 'mailFieldView2'
      this.patentLabelView = 'patentLabelView2'
      this.mailLabelView = 'mailLabelView2'
      this.captchaView = 'captchaView2'
      this.btnSearchView = 'btnSearchView2'
      this.carBox = 'carBox2'
      this.titleView = 'titleView2'
      this.btnBackToPayView = 'btnBackToPayView2'
      this.btnFindSoapView = 'btnFindSoapView2'
      this.title2NewCarView = 'title2NewCarView2'
      this.titleNewCarView = 'titleNewCarView2'
      this.patentFieldNewCarView = 'patentFieldNewCarView2'
      this.mailFieldNewCarView = 'mailFieldNewCarView2'
      this.patentLabelNewCarView = 'patentLabelNewCarView2'
      this.mailLabelNewCarView = 'mailLabelNewCarView2'
      this.captchaNewCarView = 'captchaNewCarView2'
      this.buttonsNewCarView = 'buttonsNewCarView2'
      this.title1NewCarView = 'title1NewCarView2'
      this.btnSearchNewCarView = 'btnSearchNewCarView2'
      this.btnFindSoapBox = 'btnFindSoapBox2'
      this.dataBoxAdd = 'dataBoxAdd2'
      this.formBox = 'formBox2'
      this.priceSpace = 'priceSpace2'
      this.priceSpaceCarro = 'priceSpaceCarro2'
      this.tituloMediano = 'tituloMediano2'
      this.tituloCorto = 'tituloCorto2'
      this.tituloLargo = 'tituloLargo2'
      this.subtitulo = 'subtitulo2'
      this.btnFindSoapTitleBox = 'btnFindSoapTitleBox2'
      this.titleBox = 'titleBox2'
      this.titleBoxAdd = 'titleBoxAdd2'
      this.priceTitleBox = 'priceTitleBox2'
      this.topViewNew = 'topViewNew2'
      this.btnBack = 'btnBackMobile'
      this.disclaimerText = 'disclaimerText2'

    } else if (this.screen >= 768 && this.screen <= 1000) {
      this.backgroundView = 'backgroundView'
      this.bannerView = 'bannerView'
      this.topView = 'topView'
      this.pricesView = 'pricesViewtablet'
      this.priceTag = 'priceTagtablet'
      this.title1View = 'title1View'
      this.title2View = 'title2View'
      this.cartView = 'cartView'
      this.colView = 'colView'
      this.patentFieldContainer = 'patentFieldContainer'
      this.mailFieldContainer = 'mailFieldContainer'
      this.patentFieldView = 'patentFieldView'
      this.mailFieldView = 'mailFieldView'
      this.patentLabelView = 'patentLabelView'
      this.mailLabelView = 'mailLabelView'
      this.captchaView = 'captchaView'
      this.btnSearchView = 'btnSearchView'
      this.carBox = 'carBox'
      this.btnFindSoapView = 'btnFindSoapView'
      this.titleView = 'titleView'
      this.btnBackToPayView = 'btnBackToPayView'
      this.title2NewCarView = 'title2NewCarView'
      this.titleNewCarView = 'titleNewCarView'
      this.patentFieldNewCarView = 'patentFieldNewCarView'
      this.mailFieldNewCarView = 'mailFieldNewCarView'
      this.patentLabelNewCarView = 'patentLabelNewCarView'
      this.mailLabelNewCarView = 'mailLabelNewCarView'
      this.captchaNewCarView = 'captchaNewCarView'
      this.buttonsNewCarView = 'buttonsNewCarView'
      this.title1NewCarView = 'title1NewCarView'
      this.btnSearchNewCarView = 'btnSearchNewCarView'
      this.btnFindSoapBox = 'btnFindSoapBoxTablet'
      this.dataBox = 'dataBoxTablet'
      this.dataBoxAdd = 'dataBoxAdd'
      this.formBox = 'formBoxTablet'
      this.priceSpace = 'priceSpace'
      this.priceSpaceCarro = 'priceSpace'
      this.tituloMediano = 'tituloMediano'
      this.tituloCorto = 'tituloCorto'
      this.tituloLargo = 'tituloLargo'
      this.subtitulo = 'subtitulo'
      this.btnFindSoapTitleBox = 'btnFindSoapTitleBoxTablet'
      this.titleBox = 'titleBox'
      this.titleBoxAdd = 'titleBoxAdd'
      this.priceTitleBox = 'priceTitleBoxTablet'
      this.btnBack = 'btnBack'
      this.disclaimerText = 'disclaimerText2'
    }
    // console.log('newCar: ',this.newCar)
  },
};
</script>

<style lang="css" scoped>
.disclaimerText {
  font-family: "Arial", sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #000000;
  opacity: 1;
  text-align: justify;
  margin: auto;
  width: 73%;
}

.disclaimerText2 {
  font-family: "Arial", sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #000000;
  opacity: 1;
  text-align: justify;
  margin: auto;
  width: 85%;
}

.disclaimerText3 {
  font-family: "Arial", sans-serif;
  font-size: 15px;
  letter-spacing: 0.3px;
  color: #000000;
  opacity: 1;
  text-align: justify;
  margin: auto;
  width: 53%;
}

.priceTitleBox {
  text-align: left;
  margin-bottom: 2%;
  margin-top: -2%;
}

.priceTitleBoxTablet {
  /* text-align: left; */
  margin-bottom: 2%;
  margin-top: -2%;
}

.priceTitleBox2 {
  text-align: left;
  margin-bottom: 2%;
  margin-top: -2%;
  display: flex;
  gap: 2%;
}

.titleBox2 {
  text-align: left;
  margin-left: 8%;
  margin-top: 1%;
}

.titleBoxAdd2 {
  text-align: left;
  margin-left: 5%;
  margin-top: 9%;
  gap: 8px;
  display: flex;
}

.formBox {
  background: #FFF 0% 0% no-repeat padding-box !important;
  /* box-shadow: 7px 9px 9.9px 2px #00000029; */
  border-radius: 16px;
  border: 2px solid #B5B5B5;
  width: 40%;
  padding: 1%;
  max-width: 610px;
  /* margin: auto; */
}

.formBoxTablet {
  background: #FFF 0% 0% no-repeat padding-box !important;
  /*  box-shadow: 7px 9px 9.9px 2px #00000029; */
  border-radius: 16px;
  border: 2px solid #B5B5B5;
  width: 100%;
  max-width: 610px;
  position: relative;
  margin: auto;
  margin-bottom: 6%;
}

.formBox2 {
  background: #FFF 0% 0% no-repeat padding-box !important;
  /* box-shadow: 7px 9px 9.9px 2px #00000029;  */
  border-radius: 16px;
  width: 95%;
  padding: 1%;
  border: 1px solid #B5B5B5;
  margin: auto;
}

.dataBox {
  display: flex;
  gap: 5%;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 90%;
}

.dataBoxTablet {
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  position: relative;
  margin-top: 3%;
}

.dataBox2 {
  display: block;
  margin-top: -8%;
}

.dataBoxAdd {
  display: flex;
  gap: 10%;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  margin-left: 7%;
}

.dataBoxAdd2 {
  display: block;
  margin-top: -10%;
}

.priceTag {
  display: flex;
  background-color: rgba(246, 246, 246, 1);
  border-radius: 6px;
  height: 90px;
  width: 60%;
  min-width: 455px;
}

.priceTag2 {
  display: flex;
  background-color: rgba(246, 246, 246, 1);
  border-radius: 6px;
  height: 90px;
  width: 100%;
  font-family: Arial;
}

.priceTagtablet {
  display: flex;
  background-color: rgba(246, 246, 246, 1);
  border-radius: 6px;
  height: 90px;
  width: 60%;
  min-width: 455px;
  margin: auto;
}

.pricesView {
  /* background-color: grey; */
  width: 50%;
  padding: 1%;
  max-width: 610px;
}

.pricesViewtablet {
  width: 100%;
  padding: 1%;
  max-width: 610px;
  margin: auto;
}

.pricesView2 {
  /* background-color: grey; */
  width: 90%;
  margin: auto;
  padding: 1%;
}

.patentFieldContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.mailFieldContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.titleNewCarView {
  margin-top: -4.2%;
  margin-bottom: 0.4%;
}

.titleNewCarView2 {
  margin-top: 4%;
  margin-left: 18%;
  /* margin-top: -4.2%;
  margin-bottom: 0.4%; */
  margin-bottom: 4%;
}

.obligatorio {
  text-align: center;
  font: normal normal normal 30px/36px Arial;
  letter-spacing: 0px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.vigencia {
  text-align: center;
  font: normal normal normal 15px/17px Arial;
  letter-spacing: 0px;
  color: #EF7C00;
  opacity: 1;
}


.sub {
  background: #FFF 0% 0% no-repeat padding-box;
  opacity: 1;
}

.titulo {
  text-align: left;
  font: Arial;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #2D387C;
  opacity: 1;
  margin-top: 16%;
  margin-left: 8%;
  width: 70%;
}

.titulo3 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #2D387C;
  opacity: 1;
  margin-top: 10%;
  margin-left: 9%;
  width: 70%;
}

.titulo3Moto {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #2D387C;
  opacity: 1;
  margin-top: 15%;
  margin-left: 9%;
  width: 70%;
}

.titulo2 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: #000000;
  opacity: 1;
  margin-top: 14%;
  margin-left: 11%;
  width: 50%;
}

.tituloLargo {
  text-align: left;
  font: Arial;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  margin-top: 2%;
  margin-left: 8%;
  width: 30%;
}

.tituloLargo2 {
  text-align: left;
  font: Arial;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  margin-top: 2%;
  margin-left: 8%;
  width: 40%;
  margin-right: -3%;
}

.tituloMediano {
  text-align: left;
  font: Arial;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: #000000;
  opacity: 1;
  margin-top: 4%;
  margin-left: 8%;
  width: 30%;
}

.tituloMediano2 {
  text-align: left;
  font: Arial;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  margin-top: 6%;
  margin-left: 8%;
  width: 30%;
}

.tituloCorto {
  text-align: left;
  font: Arial;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: #000000;
  opacity: 1;
  margin-top: 7%;
  margin-left: 8%;
  width: 30%;
}

.tituloCorto2 {
  text-align: left;
  font: Arial;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  margin-top: 9%;
  margin-left: 8%;
  width: 30%;
}


.tituloLargo3 {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0.23px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  margin-top: 9%;
  margin-left: 9%;
  width: 60%;
}

.subtitulo {
  width: 102px;
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0.96px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  font-weight: 500;
  margin-top: 4%;
}

.subtitulo2 {
  width: 102px;
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0.96px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  font-weight: 500;
  margin-top: 6%;

}

.rectangulo-container {
  margin-top: -13%;
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.rectangulo-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 8%;
  background: linear-gradient(to right, #A74CF2, #4F74E3);
  border-radius: 15px 15px 0 0;
}

.rectangulo {
  display: flex;
  margin-top: -2%;
  width: 180px;
  height: 73px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -1%;
  /* border-image: linear-gradient(to right, #ff8a00, #e52e71); */
}

.rectanguloMobile {
  display: flex;
  margin-top: -2%;
  width: 180px;
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  /* margin-left: 3%; */
  /* border-image: linear-gradient(to right, #ff8a00, #e52e71); */
}

.subrectangulo-container {
  margin-top: -44%;
  position: relative;
  overflow: hidden;
  /* border-radius: 3% 3% 0 0; */
}

.subrectangulo-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 7%;
  background: linear-gradient(to right, #A74CF2, #4F74E3);
  /* border-radius: 15px 15px 0 0; */
}

.subrectangulo {
  width: 200;
  height: 55px;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  background-color: #FFFFFF;
  padding: 5%;
  margin-top: -3%;
}

.bannerView {
  font-weight: bold;
  text-align: center;
  font-size: 46px;
  color: #FFFFFF;
  margin-top: 9%;
}

.bannerView2 {
  margin-top: 4%;
  font-weight: bold;
  text-align: center;
  /* margin-top: -43%; */
  font-size: 28px;
  color: #FFFFFF;
  margin-bottom: -28%;
  padding-left: 2%;
  padding-right: 2%;
  width: 94%;
  margin: auto;
}

.title1View {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 105, 185, 1);
  font-weight: bold;
  font-size: 24px;
}

.title1View2 {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 105, 185, 1);
  font-weight: bold;
  font-size: 20px;
  /* margin-left: -40%; */
}

.title1ViewAux {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 24px;
  margin-right: 11px;
}

.title1ViewAux2 {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 20px;
  /* margin-left: -40%; */
}

.title2View {
  margin-left: 1%;
  font-family: 'Arial', sans-serif;
  color: rgba(0, 105, 185, 1);
  font-weight: bold;
  font-size: 24px;
}

.title2View2 {
  font-family: 'Arial', sans-serif;
  color: rgba(0, 105, 185, 1);
  font-weight: bold;
  font-size: 20px;
  display: block;

}

.title1NewCarView {
  font-family: 'Arial', sans-serif;
  color: #2D387C;
  font-weight: bold;
  font-size: 24px;
}

.title1NewCarView2 {
  font-family: 'Arial', sans-serif;
  color: #2D387C;
  font-weight: bold;
  font-size: 20px;
  margin-left: -55%;
}

.title2NewCarView {
  margin-left: 1%;
  font-family: 'Arial', sans-serif;
  color: #4F74E3;
  font-weight: bold;
  font-size: 24px;
}

.title2NewCarView2 {
  margin-left: 1%;
  font-family: 'Arial', sans-serif;
  color: #4F74E3;
  font-weight: bold;
  font-size: 20px;
}

.patentLabelView {
  font-family: 'Arial', sans-serif;
  color: rgba(51, 51, 51, 1);
  font-size: 70%;
  margin-left: -39%;
  font-weight: 500;
}

.patentLabelView2 {
  font-family: 'Arial', sans-serif;
  color: rgba(51, 51, 51, 1);
  margin-right: 50%;
  font-size: 80%;
}

.mailLabelView {
  font-family: 'Arial', sans-serif;
  color: rgba(51, 51, 51, 1);
  font-size: 70%;
  margin-left: -39%;
  margin-top: 3%;
  font-weight: 500;
}

.mailLabelView2 {
  font-family: 'Arial', sans-serif;
  color: rgba(51, 51, 51, 1);
  margin-right: 50%;
  font-size: 80%;
}

.cartView {
  position: absolute;
  z-index: 9999 !important;
  margin-top: -6%;
  margin-left: 80%;
}

.cartView2 {
  position: absolute;
  right: 12%;
  z-index: 9999 !important;
  margin-top: -6.5%;
}

.images {
  margin-left: 6%;
}

/* .images2 {
  margin-left: 6%;
} */

.imagesMiniBus {
  margin-left: 7%;
}

.imagesMoto {
  margin-left: 7%;
}

.captchaView {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.captchaView2 {
  /* margin-top: 0%;
  margin-left: 6%; */
  margin: auto;
  width: 300px;
  margin-bottom: 10%;
}

.btnSearchView {
  width: 100%;
  max-width: 300px;
  height: 40px !important;
  background: rgba(255, 125, 0, 1) 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  /* text-align: center; */
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: 700;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
}

.btnSearchView2 {
  width: 300px;
  height: 50px !important;
  background: rgba(255, 125, 0, 1) 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  margin-left: 18% !important;
  margin-bottom: 8% !important;
}

.btnBack {
  width: 398px;
  height: 60px !important;
  background: #0069B6 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px !important;
  color: white;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
}

.btnFindSoapView {
  width: 284px;
  height: 40px;
  background: rgba(0, 105, 185, 1) 0% 0% no-repeat padding-box !important;
  border: 2px;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: 700;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

/* .btnFindSoapView:hover {
  color: #FFFFFF;
} */

.btnFindSoapView2 {
  width: 284px;
  height: 40px;
  background: rgba(0, 105, 185, 1) 0% 0% no-repeat padding-box !important;
  border-radius: 6px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: 700;
  font-family: 'Arial';
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.carBox {
  box-shadow: 3px 3px 5px 0px #00000029;
  border-radius: 15px;
}

.carBox2 {
  box-shadow: 3px 3px 5px 0px #00000029;
  border-radius: 15px;
  width: 45%;
  /* margin-left: -1%; */
}

.backgroundView {
  background-color: #FFF;
}

.btnFindSoapTitleBoxTablet {
  width: 70%;
  margin: auto;
  margin-bottom: 3%;
}

.backgroundView2 {
  background-color: #FFFFFF;
}

#app>div>main>div>div>div.topView>form {
  width: 100%;
}

#app>div>main>div>div>div.topViewNew>form {
  width: 100%;
}

.patentFieldView {
  width: 100%;
  max-width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
}

.patentFieldView2 {
  width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.mailFieldView {
  width: 100%;
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 0%; */
}

.topView2 {
  margin-top: 11%;
}


.topView {
  margin-top: -6%;
  background-color: #FFF;
}

.topViewNew {
  margin-top: -7%;
  background-color: #FFF;
}

.mailFieldView2 {
  width: 100%;
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.btnBackToPayView {
  width: 50%;
  max-width: 300px;
  height: 40px !important;
  /* background: #2D387C 0% 0% no-repeat padding-box !important; */
  background: none 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: rgba(0, 105, 185, 1) !important;
  opacity: 1;
  border: 1px solid rgba(0, 105, 185, 1);
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  margin-right: 5.8%;
  margin-left: 0.5%;
  font-size: 16px;
  margin-top: -4%;
}

.btnBackToPayView2 {
  width: 300px;
  height: 50px !important;
  background: none !important;
  border: 1px solid rgba(0, 105, 185, 1);
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: rgba(0, 105, 185, 1) !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  margin: auto;
}

.colView {
  display: block;
  gap: 10px;
}

.colView2 {
  display: block;
  margin: auto;
}

.patentFieldNewCarView {
  width: 100%;
  max-width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
  text-transform: uppercase;
}

.patentFieldNewCarView2 {
  width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 35%; */
  text-transform: uppercase;
}

.mailFieldNewCarView {
  width: 100%;
  max-width: 300px;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: 0%;
}

.mailFieldNewCarView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 35%; */
}

.patentLabelNewCarView {
  font-family: 'Arial', sans-serif;
  color: #333333;
  margin-left: -60%;
  font-size: 88%;
}

.patentLabelNewCarView2 {
  font-family: 'Arial', sans-serif;
  color: #333333;
  margin-right: 55%;
  font-size: 100%;
}

.mailLabelNewCarView {
  font-family: 'Arial', sans-serif;
  color: #333333;
  margin-right: 60%;
  font-size: 88%;
}

.mailLabelNewCarView2 {
  font-family: 'Arial', sans-serif;
  color: #333333;
  margin-right: 55%;
  font-size: 100%;
}

.captchaNewCarView {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -4%;
  margin-bottom: 1%;
}

.captchaNewCarView2 {
  margin-top: -15%;
  margin-left: 7%;
  margin-bottom: 0%;
}

.buttonsNewCarView {
  display: flex;
  gap: 10%;
  max-width: 80%;
  margin: auto;
  margin-left: 6%;
}

.buttonsNewCarView2 {
  /* display:flex;  */
  margin-top: 2%;
  margin-bottom: 4% !important;
}

.infoContenedor {
  width: 170px;
}

.space {
  /* margin-top: -3%; */
  margin-bottom: 2%;
}

.priceSpace {
  font-size: 55px;
  color: rgba(0, 0, 0, 1);
  transform: scaleX(0.5);
  margin-left: 3%;
  margin-right: 5%;
}

.priceSpace2 {
  font-size: 55px;
  color: rgba(0, 0, 0, 1);
  transform: scaleX(0.5);
  margin-left: 0%;
  margin-right: 1%;
}

.priceSpaceCarro2 {
  font-size: 55px;
  color: rgba(0, 0, 0, 1);
  transform: scaleX(0.5);
  margin-left: 2%;
  margin-right: 0%;
}

.btnSearchNewCarView {
  width: 50%;
  max-width: 300px;
  height: 40px !important;
  background: rgba(255, 125, 0, 1) 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
}

.btnSearchNewCarView2 {
  width: 300px;
  height: 50px !important;
  background: rgba(255, 125, 0, 1) 0% 0% no-repeat padding-box !important;
  border-radius: 8px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
}

/* Sacar en caso de  */
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.backgroundView div.sub div.text-center.col button#consulta_1.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.btnFindSoapView {
  margin: auto !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  margin: auto !important;
}

.btnFindSoapTitleBox {
  width: 70%;
  margin-right: -5%;
}

.btnFindSoapTitleBox2 {
  width: 100%;
  margin: auto;
  margin-bottom: 3%;
}

.btnFindSoapBox {
  /* margin-top: -2%;
  margin-bottom: -1%; */
  display: flex;
  padding: 1%;
  width: 55%;
  margin: auto;
  margin-top: 7%;
  border-radius: 6px;
  width: 75%;
  max-width: 1010px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #2d5570, 0 0 0 2px #2d5570;
}

.btnFindSoapBoxTablet {
  /* margin-top: -2%;
  margin-bottom: -1%; */
  display: block;
  padding: 1%;
  width: 55%;
  margin: auto;
  margin-top: 7%;
  width: 85%;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #2d5570, 0 0 0 2px #2d5570;

}

.btnFindSoapBox2 {
  /* margin-top: 12%; */
  margin: auto;
  /* background-color: yellowgreen; */
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(0, 105, 185, 1), 0 0 0 2px rgba(0, 105, 185, 1);

  width: 90%;
}

.v-btn:before {
  background-color: transparent !important;
}
</style>